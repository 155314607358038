<template>
  <table
    v-if="
      isCameraDiff ||
      isCrDiff ||
      isRouterDiff ||
      isNvrDeviceSettings ||
      isDeviceSettings
    "
    class="simple-table w-100"
  >
    <thead v-if="isNvrDeviceSettings || isDeviceSettings">
      <tr>
        <th width="278px" class="caption font-weight-medium">Name</th>
        <th width="230px" class="caption font-weight-medium">Value</th>
      </tr>
    </thead>

    <thead v-else>
      <tr>
        <th width="278px" class="caption font-weight-medium">Settings</th>
        <th width="230px" class="caption font-weight-medium">Old</th>
        <th class="caption font-weight-medium">New</th>
      </tr>
    </thead>

    <tbody v-if="isCrDiff">
      <tr
        v-for="(setting, index) in CLOUD_RECORDINGS_SETTINGS"
        v-show="hasCrDiffForAttr(setting.attr)"
        :key="index"
      >
        <td>{{ setting.name }}</td>
        <td>{{ crDiff.old[setting.attr] }}</td>
        <td>{{ crDiff.new[setting.attr] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isRouterDiff">
      <tr
        v-for="(value, key) in routerDiff.old"
        v-show="hasChanged(routerDiff, key)"
        :key="key"
      >
        <td>{{ ROUTER_SETTINGS[key] || key }}</td>
        <td>{{ routerDiff.old[key] }}</td>
        <td>{{ routerDiff.new[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isLayerDiff">
      <tr
        v-for="(value, key) in diff.old"
        v-show="hasChanged(diff, key)"
        :key="key"
      >
        <td>{{ LAYER_SETTINGS[key] || key }}</td>
        <td>{{ diff.old[key] }}</td>
        <td>{{ diff.new[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isDeviceSettings">
      <tr v-for="(setting, key) in ROUTER_DEVICE_SETTINGS" :key="key">
        <td>{{ ROUTER_DEVICE_SETTINGS[key] }}</td>
        <td>{{ deviceSetings[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else-if="isNvrDeviceSettings">
      <tr v-for="(setting, key) in NVR_SETTINGS" :key="key">
        <td>{{ NVR_SETTINGS[key] }}</td>
        <td>{{ nvrSettings[key] }}</td>
      </tr>
    </tbody>

    <tbody v-else>
      <tr
        v-for="(value, key) in diff.old"
        v-show="hasChanged(diff, key)"
        :key="key"
      >
        <td>{{ CAMERA_SETTINGS[key] || key }}</td>
        <td>{{ diff.old[key] }}</td>
        <td>{{ diff.new[key] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {
  CAMERA_SETTINGS,
  CLOUD_RECORDINGS_SETTINGS,
  ROUTER_SETTINGS,
  ROUTER_DEVICE_SETTINGS,
  NVR_SETTINGS,
  LAYER_SETTINGS,
} from "@/components/constants.js"
import LOG_TYPES from "@evercam/shared/constants/logTypes"
export default {
  name: "CameraLogDiff",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    diff() {
      return this.item.details?.settings
    },
    crDiff() {
      return this.item.details?.settings || {}
    },
    routerDiff() {
      return this.item.details?.router?.settings || {}
    },
    deviceSetings() {
      return this.item.details?.router?.deviceSettings || {}
    },
    nvrSettings() {
      let hddInfo = {
        hddName: "",
        hddCapacity: "",
        freeSpace: "",
        hddStatus: "",
        hddProperty: "",
      }
      if (Object.keys(this.item.details?.hddInfo).length > 0) {
        this.item.details?.hddInfo.map((hdd, index) => {
          let textSeparator = index === 0 ? "" : "/"
          hddInfo.hddName += `${textSeparator}${hdd.name}`
          hddInfo.hddCapacity += `${textSeparator}${hdd.capacity}`
          hddInfo.freeSpace += `${textSeparator}${hdd.freeSpace}`
          hddInfo.hddStatus += `${textSeparator}${hdd.status}`
          hddInfo.hddProperty += `${textSeparator}${hdd.property}`
        })
      }

      return {
        ...this.item.details,
        ...hddInfo,
      }
    },
    isCrDiff() {
      return (
        this.item.details?.settings != undefined &&
        (this.item.action === LOG_TYPES.CR_UPDATED ||
          this.item.action === LOG_TYPES.CR_CREATED)
      )
    },
    isCameraDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        this.item.details?.settings != undefined
      )
    },
    isLayerDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        this.item.details?.settings != undefined &&
        this.item.entity === "layers"
      )
    },
    isRouterDiff() {
      return (
        this.item.action === LOG_TYPES.UPDATE &&
        this.item.details?.router?.settings != undefined
      )
    },
    isDeviceSettings() {
      return (
        this.item.action === LOG_TYPES.DEVICE_SETTINGS &&
        this.item.details?.router?.deviceSettings != undefined
      )
    },
    isNvrDeviceSettings() {
      return (
        this.item.action === LOG_TYPES.DEVICE_SETTINGS &&
        this.item.entity === "nvr"
      )
    },
  },
  created() {
    this.CAMERA_SETTINGS = CAMERA_SETTINGS
    this.CLOUD_RECORDINGS_SETTINGS = CLOUD_RECORDINGS_SETTINGS
    this.ROUTER_SETTINGS = ROUTER_SETTINGS
    this.ROUTER_DEVICE_SETTINGS = ROUTER_DEVICE_SETTINGS
    this.NVR_SETTINGS = NVR_SETTINGS
    this.LAYER_SETTINGS = LAYER_SETTINGS
  },
  methods: {
    hasChanged(diff, key) {
      return diff.old[key] !== diff.new[key]
    },
    hasCrDiffForAttr(attr) {
      return this.crDiff.new[attr] !== this.crDiff.old[attr]
    },
  },
}
</script>

<style lang="scss">
.simple-table {
  border-collapse: collapse;
  th {
    text-align: left;
  }
}
</style>
